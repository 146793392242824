.skills-section {
    padding: 50px 0;
    background-color: #f8f9fa;
}

.skills-heading {
    font-size: 2rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 20px;
    text-align: center;
}

.skills-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skills-item {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    color: #495057;
    transition: transform 0.2s ease;
}

.skills-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
